import { DisconnectButton, LiveKitRoom, RoomAudioRenderer } from '@livekit/components-react';
import { Button, Input } from 'antd';
import { FC, useState } from 'react';
import toast from 'react-hot-toast';
import { MdOutlineCallEnd } from 'react-icons/md';
import axios from '../../config/axios.ts';
import { AIAssistant } from '../../types/ai-assistant.types.ts';

type WebCallProps = {
	assistantId: string;
	variables: AIAssistant['variables'];
	setStep: (step: number) => void;
};

const serverUrl = 'wss://puretalk-dqrnrwym.livekit.cloud';

export const WebCall: FC<WebCallProps> = (props) => {
	const [isCalling, setIsCalling] = useState<boolean>(false);
	const [loading, setLoading] = useState<boolean>(false);
	const [token, setToken] = useState<string | null>(null);
	const [variables, setVariables] = useState<
		{
			var_name: string;
			var_value: string;
		}[]
	>([]);

	const startCall = async () => {
		try {
			// Get permission to use the microphone
			await navigator.mediaDevices.getUserMedia({ audio: true });

			// Start the conversation
			setLoading(true);
			const response = await axios.post(
				'/calls/web/register',
				{
					assistant_id: props.assistantId,
					variables,
				},
				{ withCredentials: true },
			);

			// set the token to connect to the room
			setToken(response.data.results.token);

			setIsCalling(true); // Update button to "End" when conversation starts
		} catch (error) {
			if (error instanceof Error && error.name === 'NotAllowedError') {
				toast.error('Permission denied to use the microphone');
			} else {
				toast.error("Couldn't start the call. Please try again later.");
			}
		} finally {
			setTimeout(() => {
				setLoading(false);
			}, 500);
		}
	};

	const endCall = () => {
		setToken(null);
		setIsCalling(false);
		props.setStep(1);
	};

	return (
		<section>
			{props.variables.length > 0 && (
				<div className="mb-4">
					<h2 className="text-lg font-semibold text-black-7 dark:text-white">Enter Variables</h2>

					<div className="pb-3 font-medium text-black-3">Add variables to personalize the call. These variables are optional.</div>

					<div className="flex flex-col gap-3">
						{props.variables.map((variable) => (
							<div
								key={variable}
								className="flex items-center gap-2"
							>
								<div className="w-max text-base font-semibold">{`{{${variable}}}`}</div>
								<Input
									placeholder="Enter variable value"
									onChange={(e) =>
										setVariables((prevVariables) => {
											const variables = prevVariables.filter((v) => v.var_name !== variable || e.target.value);
											const index = variables.findIndex((v) => v.var_name === variable);

											if (index === -1 && e.target.value) {
												variables.push({ var_name: variable, var_value: e.target.value });
											} else if (index !== -1) {
												variables[index].var_value = e.target.value;
											}

											return variables;
										})
									}
								/>
							</div>
						))}
					</div>
				</div>
			)}

			<div className="flex h-[180px] items-center justify-center rounded-lg border dark:border-dark-border">
				{token ? (
					<div>
						<LiveKitRoom
							token={token}
							serverUrl={serverUrl}
							connect={true}
							audio={true}
							video={false}
							onMediaDeviceFailure={() => {}}
							onDisconnected={() => {
								setToken(null);
							}}
						>
							<RoomAudioRenderer />
							<DisconnectButton>
								<Button
									size={'large'}
									type={'primary'}
									block={props.variables.length > 0}
									icon={
										isCalling ? (
											<MdOutlineCallEnd size={20} />
										) : (
											<img
												src="/images/phone-numbers/make-outbound-call.svg"
												alt="make-outbound-call"
											/>
										)
									}
									danger={isCalling}
									loading={loading}
									onClick={endCall}
									className="text-lg font-medium"
								>
									End Web Call
								</Button>
							</DisconnectButton>
						</LiveKitRoom>
					</div>
				) : (
					<Button
						size={'large'}
						type={'primary'}
						icon={
							isCalling ? (
								<MdOutlineCallEnd size={20} />
							) : (
								<img
									src="/images/phone-numbers/make-outbound-call.svg"
									alt="make-outbound-call"
								/>
							)
						}
						danger={isCalling}
						loading={loading}
						onClick={startCall}
						className="text-lg font-medium"
					>
						Start Web Call
					</Button>
				)}
			</div>
		</section>
	);
};
