import { Card } from 'antd';
import { FC } from 'react';
import { cn } from '../../config/cn.ts';
import dayjs from '../../config/dayjs.ts';
import { formatSeconds } from '../../utils/helpers.ts';
import { CallLog } from '../../views/call-logs.tsx';

type Props = {
	history: CallLog;
};

const CallDurationCard: FC<Props> = ({ history }) => {
	return (
		<Card styles={{ body: { padding: 16 } }}>
			<h2 className="text-xl font-semibold text-black-7 dark:text-white">Call Duration</h2>

			<div className="pt-3" />

			<div className="space-y-3">
				{[
					{
						label: 'Call Type',
						value: history.call_type,
					},
					{
						label: 'Start Time',
						value: dayjs(history.start_time).format('YYYY-MM-DD hh:mm:ss'),
					},
					{
						label: 'End Time',
						value: history.end_time ? dayjs(history.end_time).format('YYYY-MM-DD hh:mm:ss') : 'N/A',
					},
					{
						label: 'Duration',
						value: formatSeconds(history.call_duration_seconds ?? 0),
					},
					{
						label: 'Recorded',
						value: history.call_recording_url ? 'Yes' : 'No',
					},
					{
						label: 'Call Status',
						value: history.status,
					},
				].map((item, i) => (
					<div
						key={i}
						className="space-y-0.5"
					>
						<div className="text-base font-medium text-black-7 dark:text-white">{item.label}</div>
						<div
							className={cn('text-sm font-medium text-black-3', {
								capitalize: ['Call Type', 'Call Status'].includes(item.label),
							})}
						>
							{item.value}
						</div>
					</div>
				))}
			</div>
		</Card>
	);
};

export default CallDurationCard;
