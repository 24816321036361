import { PlusOutlined } from '@ant-design/icons';
import { Button, Skeleton } from 'antd';
import { useEffect, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { Link } from 'react-router-dom';
import CampaignCard from '../components/campaign/campaign-card';
import Loader from '../components/Loader';
import axios from '../config/axios';
import AppLayout from '../layouts/app.layout';

export type Campaign = {
	ai_assistant: AIAssistant;
	schedules: Schedule[] | [];
	campaign_id: string;
	type: string;
	name: string;
	start_date: Date;
	end_date: Date;
	status: 'active' | 'paused' | 'completed' | 'terminated';
	created_at: Date;
	updated_at: Date;
	total_tasks: number;
	completed_tasks: number;
	pending_tasks: number;
};

export type AIAssistant = {
	id: number;
	assistant_id: string;
	name: string;
};

export type Schedule = {
	campaign_schedule_id: string;
	day: string;
	start_time: string;
	end_time: string;
};

const Campaigns = () => {
	const [page, setPage] = useState(1);
	const [campaigns, setCampaigns] = useState<Campaign[]>([]);
	const [count, setCount] = useState(0);
	const [loading, setLoading] = useState(true);
	const [refresh, setRefresh] = useState<number>(0);

	// get all campaigns
	useEffect(() => {
		(async () => {
			try {
				const { data } = await axios.get('/campaigns', {
					withCredentials: true,
					params: {
						page,
						limit: 12,
					},
				});
				setCount(data.results.count);
				if (page === 1) {
					setCampaigns(data.results.campaigns);
				} else {
					setCampaigns((prev) => [...prev, ...data.results.campaigns]);
				}
			} finally {
				setLoading(false);
			}
		})();
	}, [page, refresh]);

	return (
		<AppLayout
			title="Campaigns"
			subtitle="Manage your campaigns from here."
		>
			<div className="flex flex-wrap items-center justify-between gap-4">
				<h2 className="text-xl font-semibold dark:text-white">Manage Campaigns</h2>
				<Link to="/create-campaign">
					<Button
						type="primary"
						icon={<PlusOutlined />}
						className="font-semibold"
					>
						Create Campaigns
					</Button>
				</Link>
			</div>

			<div className="pt-5" />

			{loading && (
				<div className="grid grid-cols-1 gap-5 md:grid-cols-2 2xl:grid-cols-3">
					{Array.from({ length: 9 }).map((_, index) => (
						<Skeleton.Node
							active
							fullSize
							className="!h-72 !w-full"
							key={index}
						>
							<div>{null}</div>
						</Skeleton.Node>
					))}
				</div>
			)}

			{!loading && campaigns.length === 0 && <div className="font-medium text-black-3">No campaigns found.</div>}

			{!loading && campaigns.length > 0 && (
				<InfiniteScroll
					dataLength={campaigns.length}
					next={
						campaigns.length < count
							? () => {
									setPage((prev) => prev + 1);
								}
							: () => {}
					}
					hasMore={count > campaigns.length}
					style={{
						overflow: 'hidden',
					}}
					loader={
						<div className="my-3 flex items-center justify-center">
							<Loader />
						</div>
					}
					scrollThreshold={0.9}
					scrollableTarget="body"
				>
					<div className="grid grid-cols-1 gap-5 md:grid-cols-2 2xl:grid-cols-3">
						{campaigns.map((campaign) => (
							<CampaignCard
								key={campaign.campaign_id}
								campaign={campaign}
								setRefresh={setRefresh}
							/>
						))}
					</div>
				</InfiniteScroll>
			)}

			<div className="pb-6" />
		</AppLayout>
	);
};

export default Campaigns;
