import { LayoutOutlined, TableOutlined } from '@ant-design/icons';
import { Button, Card, Divider, Modal, Table } from 'antd';
import { Fragment, useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { BsChevronDown } from 'react-icons/bs';
import { FaRegCopy } from 'react-icons/fa6';
import { GoCheckCircleFill } from 'react-icons/go';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useSearchParams } from 'react-router-dom';
import ChatCountCard from '../components/call-logs/chat-count-card.tsx';
import ChatInformationCard from '../components/call-logs/chat-information-card.tsx';
import ChatLogsDrawer from '../components/call-logs/chat-logs-drawer.tsx';
import ChatTranscriptCard from '../components/call-logs/chat-transcript-card.tsx';
import Loader from '../components/Loader';
import axios from '../config/axios';
import { cn } from '../config/cn.ts';
import dayjs from '../config/dayjs';
import AppLayout from '../layouts/app.layout';

export type ChatLog = {
	chat_id: string;
	assistant_id: string;
	assistant_name: string;
	transcript: string;
	transcript_object: TranscriptObject[];
	user_message_count: number;
	assistant_message_count: number;
	tool_invocation_count: number;
	created_at: Date;
};

export type TranscriptObject = {
	role: Role;
	content: string;
	name?: string;
};

export type Role = 'user' | 'assistant' | 'function';

const ChatLogs = () => {
	const [searchParams, setSearchParams] = useSearchParams();
	const [history, setHistory] = useState<ChatLog | undefined>();
	const [historyLoading, setHistoryLoading] = useState(true);
	const [page, setPage] = useState(1);
	const [loading, setLoading] = useState(true);
	const [logs, setLogs] = useState<ChatLog[]>([]);
	const [count, setCount] = useState(0);
	const [logsModalOpen, setLogsModalOpen] = useState(false);
	const [copied, setCopied] = useState<boolean>(false);
	const [copiedId, setCopiedId] = useState<string | null>(null);
	const [viewMode, setViewMode] = useState<'list' | 'grid'>('grid');

	const toggleViewMode = () => {
		setViewMode((prev) => (prev === 'list' ? 'grid' : 'list'));
	};

	const [logDrawer, setLogDrawer] = useState<{
		open: boolean;
		data: ChatLog | null;
	}>({
		open: false,
		data: null,
	});

	// get phone number from url
	const selected_chat = searchParams.get('history');

	// get all chat logs
	useEffect(() => {
		(async () => {
			try {
				const { data } = await axios.get('/chats/sessions', {
					withCredentials: true,
					params: {
						page,
						limit: 20,
					},
				});
				setCount(data.count);

				if (viewMode === 'list') {
					setLogs(data.results);
				}

				if (viewMode === 'grid') {
					if (page === 1) {
						setLogs(data.results);
					} else {
						setLogs((prev) => [...prev, ...data.results]);
					}
				}
			} catch (error) {
				console.error(error);
			} finally {
				setLoading(false);
			}
		})();
	}, [page, viewMode]);

	// set first chat as default
	useEffect(() => {
		if (!searchParams.get('history') && logs.length > 0) {
			setSearchParams({
				history: String(logs[0].chat_id),
			});
		}
	}, [logs, searchParams, setSearchParams]);

	// get chat history details
	useEffect(() => {
		setHistoryLoading(true);
		(async () => {
			if (selected_chat) {
				const { data } = await axios.get(`/chats/sessions/${selected_chat}`, { withCredentials: true });
				setHistory(data.results);
				setHistoryLoading(false);
			}
		})();
	}, [selected_chat]);

	// reset copied state after 2 seconds
	useEffect(() => {
		if (copied) {
			const timeout = setTimeout(() => {
				setCopied(false);
			}, 2000);

			return () => clearTimeout(timeout);
		}
	}, [copied]);

	return (
		<AppLayout
			title="Chat Logs"
			subtitle="View and manage chat logs here."
		>
			{loading && (
				<Card styles={{ body: { padding: 0, height: 'calc(100dvh - 8rem)' } }}>
					<div className="flex h-full items-center justify-center">
						<Loader />
					</div>
				</Card>
			)}

			{!loading && logs.length === 0 && (
				<Card styles={{ body: { padding: 0, height: 'calc(100dvh - 8rem)' } }}>
					<div className="flex h-full flex-col items-center justify-center gap-2">
						<div className="text-base font-medium text-black-3">No chat logs found.</div>
					</div>
				</Card>
			)}

			{!loading && logs.length > 0 && viewMode === 'list' && (
				<>
					<Table
						title={() => (
							<div className="flex items-center justify-between">
								<div className="text-xl font-semibold leading-none text-black-7 dark:text-white">Chat History</div>

								<div className={'flex items-center gap-1.5'}>
									<Button
										icon={<LayoutOutlined />}
										onClick={toggleViewMode}
										title={'Switch to Grid View'}
									/>
								</div>
							</div>
						)}
						dataSource={logs}
						size={'middle'}
						bordered={true}
						pagination={{
							pageSize: 20,
							total: count,
							showSizeChanger: false,
							onChange: (page) => {
								setPage(page);
							},
						}}
						rowKey={(record) => record.chat_id}
						onRow={(record) => {
							return {
								onClick: () => {
									setLogDrawer({
										open: true,
										data: record,
									});
								},
							};
						}}
						rowClassName={'cursor-pointer'}
						columns={[
							{
								title: 'Time',
								dataIndex: 'start_time',
								key: 'start_time',
								render: (start_time: Date) => dayjs(start_time).format('YYYY-MM-DD hh:mm'),
							},
							{
								title: 'Assistant Name',
								dataIndex: 'assistant_name',
								key: 'assistant_name',
								render: (assistant_name: string) => assistant_name,
							},
							{
								title: 'Chat ID',
								dataIndex: 'chat_id',
								key: 'chat_id',
								render: (chat_id: string) => (
									<div className="flex items-center gap-2">
										<div>{chat_id}</div>
										<div
											onClick={async (e) => {
												e.stopPropagation();
												await navigator.clipboard.writeText(chat_id);
												setCopied(true);
												setCopiedId(chat_id);
												toast.success('Copied to clipboard.');
											}}
											role="button"
										>
											<div className="text-sm text-gray-500">
												{copied && copiedId === chat_id ? <GoCheckCircleFill color="green" /> : <FaRegCopy />}
											</div>
										</div>
									</div>
								),
							},
							{
								title: 'User Messages',
								dataIndex: 'user_message_count',
								key: 'user_message_count',
							},
							{
								title: 'Assistant Messages',
								dataIndex: 'assistant_message_count',
								key: 'assistant_message_count',
							},
							{
								title: 'Tool Invocations',
								dataIndex: 'tool_invocation_count',
								key: 'tool_invocation_count',
							},
						]}
					/>
				</>
			)}

			{!loading && logs.length > 0 && viewMode === 'grid' && (
				<div className="flex flex-col gap-5 md:flex-row">
					{/* mobile version */}
					<div className="md:hidden">
						{logs.map((log, i) => {
							if (log.chat_id === searchParams.get('history')) {
								return (
									<Card
										key={i}
										styles={{ body: { padding: 10 } }}
										role="button"
										className="shadow-sm"
										onClick={() => {
											// open modal
											setLogsModalOpen(true);
										}}
									>
										<div className="flex items-center justify-between gap-2">
											<div className="flex items-center gap-2.5">
												<img
													src="/images/call-logs/call-logs.svg"
													alt="call-logs"
												/>

												<section className="flex flex-col">
													<div className="text-base font-medium capitalize text-black-7 dark:text-white">
														{log.assistant_name}
													</div>
													<div className="text-sm font-medium text-black-3">
														{dayjs(log.created_at).format('YYYY-MM-DD hh:mm')}
													</div>
												</section>
											</div>

											<div>
												<BsChevronDown size={20} />
											</div>
										</div>
									</Card>
								);
							}
							return null;
						})}

						{/* logs modal */}
						<Modal
							title="Chat History"
							open={logsModalOpen}
							onCancel={() => setLogsModalOpen(false)}
							footer={null}
							centered
						>
							<div className="h-[calc(100dvh-10rem)] overflow-hidden">
								<div
									className="scrollbar-hidden h-full overflow-y-auto"
									id="log-scrollable-mobile"
								>
									<InfiniteScroll
										dataLength={logs.length}
										next={
											logs.length < count
												? () => {
														setPage((prev) => prev + 1);
													}
												: () => {}
										}
										hasMore={count > logs.length}
										style={{
											overflow: 'hidden',
										}}
										loader={
											<div className="my-3 flex items-center justify-center">
												<Loader />
											</div>
										}
										scrollThreshold={0.9}
										scrollableTarget="log-scrollable-mobile"
									>
										<div className="pt-3" />

										<div className="flex flex-col gap-3.5">
											{logs.map((log: ChatLog, i: number) => (
												<div
													key={i}
													className={cn(
														'rounded-lg border bg-white px-2 py-2.5 shadow-sm transition-all duration-300 dark:bg-[#161422]',
														{
															'border-primary': searchParams.get('history') === log.chat_id,
															'dark:border-dark-border': searchParams.get('history') !== log.chat_id,
														},
													)}
													onClick={() => {
														setSearchParams({
															history: log.chat_id,
														});
														setLogsModalOpen(false);
													}}
													role="button"
												>
													<div className="flex items-center gap-2.5">
														<img
															src="/images/call-logs/call-logs.svg"
															alt="call-logs"
														/>

														<section className="flex flex-col">
															<div className="text-base font-medium capitalize text-black-7 dark:text-white">
																{log.assistant_name}
															</div>
															<div className="text-sm font-medium text-black-3">
																{dayjs(log.created_at).format('YYYY-MM-DD hh:mm')}
															</div>
														</section>
													</div>
												</div>
											))}
										</div>
									</InfiniteScroll>
								</div>
							</div>
						</Modal>
					</div>

					{/* desktop version */}
					<div className="sticky top-28 hidden h-[calc(100dvh-8rem)] w-[21rem] overflow-hidden md:block">
						<Card
							className="h-full overflow-y-auto bg-input-bg dark:bg-dark-sidebar"
							styles={{ body: { padding: 0 } }}
							id="log-scrollable"
						>
							<div className="flex items-center justify-between rounded-t-lg border-b bg-white px-3 py-[12px] dark:border-b-[#2d2b38] dark:bg-[#161422]">
								<div className="text-xl font-semibold leading-none text-black-7 dark:text-white">Chat History</div>
								<Button
									icon={<TableOutlined />}
									onClick={toggleViewMode}
									title={'Switch to Table View'}
								/>
							</div>

							<InfiniteScroll
								dataLength={logs.length}
								next={
									logs.length < count
										? () => {
												setPage((prev) => prev + 1);
											}
										: () => {}
								}
								hasMore={count > logs.length}
								style={{
									overflow: 'hidden',
								}}
								loader={
									<div className="my-3 flex items-center justify-center">
										<Loader />
									</div>
								}
								scrollThreshold={0.9}
								scrollableTarget="log-scrollable"
							>
								<div className="flex flex-col gap-3.5 p-3">
									{logs.map((log: ChatLog, i: number) => (
										<div
											key={i}
											className={cn(
												'rounded-lg border bg-white px-2 py-2.5 shadow-sm transition-all duration-300 dark:bg-[#161422]',
												{
													'border-primary': searchParams.get('history') === log.chat_id,
													'dark:border-[#14121f]': searchParams.get('history') !== log.chat_id,
												},
											)}
											onClick={() => {
												setSearchParams({
													history: log.chat_id,
												});
											}}
											role="button"
										>
											<div className="flex items-center gap-2.5">
												<img
													src="/images/call-logs/call-logs.svg"
													alt="call-logs"
												/>

												<section className="flex flex-col">
													<div className="text-base font-medium capitalize text-black-7 dark:text-white">
														{log.assistant_name}
													</div>
													<div className="text-sm font-medium text-black-3">
														{dayjs(log.created_at).format('YYYY-MM-DD hh:mm')}
													</div>
												</section>
											</div>
										</div>
									))}
								</div>
							</InfiniteScroll>
						</Card>
					</div>
					<div className="flex-1 pt-2">
						<div className="h-full">
							{selected_chat ? (
								<Fragment>
									{historyLoading && (
										<div className="flex h-[calc(100dvh-20rem)] items-center justify-center md:h-[calc(100dvh-9rem)]">
											<Loader />
										</div>
									)}

									{!historyLoading && history && (
										<section>
											<div className="flex items-center justify-between gap-1">
												<section>
													<h2 className="text-xl font-bold capitalize dark:text-white">{history.assistant_name}</h2>

													<div className="text-sm font-medium text-black-3">
														{dayjs(history.created_at).format('YYYY-MM-DD hh:mm')}
													</div>
												</section>
											</div>

											<Divider />

											<div className="grid grid-cols-1 gap-4 sm:grid-cols-2 md:grid-cols-1 lg:grid-cols-2">
												<ChatInformationCard history={history} />

												<ChatCountCard history={history} />
											</div>

											<div className="pt-6" />

											<ChatTranscriptCard history={history} />
										</section>
									)}

									<div className="pb-6" />
								</Fragment>
							) : (
								<Card className="flex h-full items-center justify-center">
									<div className="flex flex-col gap-2 text-center">
										<div className="text-base font-medium text-gray-600">Select a log to view details.</div>
									</div>
								</Card>
							)}
						</div>
					</div>
				</div>
			)}

			{logDrawer.data ? (
				<ChatLogsDrawer
					open={logDrawer.open}
					close={() => setLogDrawer((prev) => ({ ...prev, open: false }))}
					history={logDrawer.data}
				/>
			) : null}
		</AppLayout>
	);
};

export default ChatLogs;
